p, h1, h2, h3, h4, h5, button, label, div.ant-typography {
  font-family: "Lexend Deca", serif !important;
  font-optical-sizing: auto;
  font-style: normal;
}

h4.ant-typography.channel-heading {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 1rem;
}

h5.ant-typography.channel-list-item {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

div.ant-typography.channel-list-description {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 1rem;
}

div.channel-list-item {
  padding-left: 24px;
}

.configure-channel-card {
  margin: 1.5rem;
  max-width: 800px; 
}

.configure-channel-card .ant-card-head {
  border-bottom: none;
}

.configure-channel-card .ant-card-body {
  padding-top: 12px;
}

label.channels-label-senderid {
  font-size: 14px;
  font-weight: 500;
}

p.help-text-senderid {
  color: #516F90;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}

.channel-logo {
  padding-bottom: 1.5rem;
  width: 287px;
}

.sender-id {
  width: 100%;
}

.helpTextSenderId {
  font-size: smaller;
  margin: 0;
}

.button-wrapper {
  margin-top: 1rem;
  position: relative;
  text-align: right;
}

.button-wrapper .channels-support-article {
  font-size: 12px;
  position: absolute;
}

.ant-typography a.support-article-link,
.ant-typography a.support-article-link:hover,
.ant-typography a.support-article-link:active {
  color: #007A8C;
  text-decoration: underline;
}

.button-wrapper > button {
  margin-right: 10px;
}

.button-wrapper > *:last-child {
  margin-right: 0px;
}

.spinner-layout {
  background: #fafafa;
  overflow: hidden;
  overflow-y: auto;
}

.spinner-layout .spin {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

button.channel-btn, button.channel-btn:hover {
  background: #425B76;
  
  border-color: #425B76;
  border-radius: 3px;
  
  color: white;
  
  font-weight: 400;
  
  line-height: 1;
  
  min-height: 38px; 

  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
}

button.channel-btn-normal, button.channel-btn-normal:hover {
  background: #EAF0F6;
  
  border-color: #CBD6E2;
  border-radius: 3px;
  
  color: #506E91;
  
  font-weight: 400;
  
  line-height: 1;

  min-height: 38px;
  
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
}

.channel-connect-success, .channel-connect-fail {
  border-style: solid;
  border-width: 1px;

  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
}

.channel-connect-success {
  background: #E5F8F6;
  border-color: #7FDED2;
}

.channel-connect-fail {
  background: #FDEDEE;
  border-color: #F8A9AD;
}

h4.ant-typography.channel-connect-title {
  color: #33475B !important;
  font-size: 16px;
  font-weight: 700;
}

.channel-connect-text {
  color: #33475B !important;
  font-size: 14px;
  font-weight: 300;
}

.channel-list-item-icon {
  left: 24px;
  position: absolute;
}

.channel-list-item-icon svg {
  color: #0A5D9A !important;
  font-size: 16px;
}